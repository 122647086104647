@use 'partials' as *;

.toggleIconButton {
  position: relative;
  top: auto;
  left: auto;
  display: block;
  width: 2em;
  height: 2em;
  padding-right: 2.5em;
  background: none;
  border: none;
}

.toggleIconButton::after,
.toggleIconButton::before {
  position: absolute;
  top: calc(50% - 0.1em);
  display: block;
  width: 2em;
  height: size(1);
  content: '';
  background: var(--color-white);
}

.toggleIconButton::before {
  box-shadow: 0 0.8em 0 var(--color-white);
  transition: transform 0.3s;
  transform: translateY(-0.8em);
}

.toggleIconButton::after {
  transition: transform 0.3s;
  transform: translateY(0.8em);
}

.toggleIconButton.active::before {
  box-shadow: none;
  transform: rotate(45deg);
}

.toggleIconButton.active::after {
  transform: rotate(-45deg);
}

// SPのみ
@include media-max(sp) {
  .active {
    z-index: $z-index-menu + 1;
  }
}
