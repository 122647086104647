@use 'partials' as *;

.componentStyle {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: size(20) 0;
  font-size: 1em;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 90%;
    height: size(1);
    content: '';
    background-color: var(--color-lightgray);
  }
}

.icon {
  width: 1.3em;
  max-height: 1.3em;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.badge {
  position: absolute;
  top: size(19);
  left: size(8);
}

@include media-min(pc) {
  .componentStyle {
    width: 100%;
    padding: size(20) 0;
  }

  .badge {
    position: absolute;
    top: size(17);
    left: size(10);
    width: size(12);
    height: size(12);
  }
}
