@use 'partials' as *;
@use 'sass:map';

.componentStyle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-menu;
  width: 200vw;
  height: 100vh;
}

.container {
  width: size(325);
  height: 100vh;
  padding: size(48) size(24) size(100) size(24);
  overflow: auto;
  overscroll-behavior-y: contain;
  color: var(--color-black);
  background-color: var(--color-white);
}

.profile {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.profileDetail {
  width: calc(100% - #{size(100)});
}

.profileName {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: size(18);
  font-weight: bold;
}

.menuCoinLabel {
  font-size: size(10);
  font-weight: bold;
  word-break: break-all;
  word-wrap: break-word;
}

.menuCoin {
  margin-top: size(10);
  font-size: size(15);
}

.menuList {
  display: flex;
  flex-flow: column nowrap;
  font-size: size(12);

  li {
    position: relative;
  }
}

.hr {
  margin-top: size(40);
  background-color: var(--color-lightgray);
}

@include media-min(pc) {
  .componentStyle {
    top: size(100); // ヘッダーの高さ分
    z-index: #{$z-index-header - 1};
    width: 100vw;
    height: 100vh;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      height: size(450);
      content: '';
      background-color: var(--color-white);
    }
  }

  .container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: size($width-pc);
    max-width: 100vw;
    height: size(450);
    padding: 0 size(40);
    margin: 0 auto;
    background-color: transparent;
    background-color: var(--color-white);
  }

  .profile {
    flex-shrink: 0;
    width: size(460);
    height: 100%;
    padding: size(50) 0 size(20) 0;
    overflow: hidden;
  }

  .profileName {
    font-size: size(24);
  }

  .menuCoinLabel {
    font-size: size(12);
  }

  .menuCoin {
    margin-top: 0;
    font-size: size(24);
  }

  .menuList {
    flex-flow: column wrap;
    align-content: space-between;
    width: size(254 * 2 + 40);
    height: 100%;
    padding-top: size(20);
    margin-top: 0;
    font-size: size(14);

    & > li {
      width: size(254);
    }
  }

  .textIcon {
    width: 1.3em;
    max-height: 1.3em;
  }

  .hr {
    display: none;
  }
}

// アニメーション
// SP
@include media-max(sp) {
  .enter {
    background-color: transparent;
    transform: translateX(-100vw);
  }

  .enterActive {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transition: all 0.2s ease-in;
    transform: translateX(0);
  }

  .enterDone {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transform: translateX(0);
  }

  .exit {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transform: translateX(0);
  }

  .exitActive {
    background-color: transparent;
    transition: all 0.2s ease-in;
    transform: translateX(-100vw);
  }

  .exitDone {
    background-color: transparent;
    transform: translateX(-100vw);
  }
}

// PC
@include media-min(pc) {
  .enter {
    background-color: transparent;
    transform: translateY(-100vh);
  }

  .enterActive {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transition: all 0.2s ease-in;
    transform: translateY(0);
  }

  .enterDone {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transform: translateY(0);
  }

  .exit {
    background-color: rgba(map.get($colors, 'black'), 0.8);
    transform: translateY(0);
  }

  .exitActive {
    background-color: transparent;
    transition: all 0.2s ease-in;
    transform: translateY(-100vh);
  }

  .exitDone {
    background-color: transparent;
    transform: translateY(-100vh);
  }
}
