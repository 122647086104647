@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.defaultText {
  font-size: 1em;
}

.xLargeText {
  font-size: size(18);
}

.icon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 1.2em;
  max-height: 1.2em;
  font-size: 1em;
}

.text {
  margin-left: 0.3em;
  font-weight: bold;
}

.unit {
  margin-top: 0.1em;
  margin-left: 0.1em;
  font-size: 0.7em;
}

.boldUnit {
  font-weight: bold;
}

@include media-min(pc) {
  .xLargeText {
    font-size: size(24);
  }
}
