@use 'partials' as *;
@use 'sass:map';

/******
 * どこでも使用できる汎用出来なスタイル
 ******/

// ***
// 文字サイズ（汎用）
// ***

.dBlock {
  display: block;
}

// スマホのみ表示する
.onlySp {
  @include media-min(pc) {
    display: none !important;
  }
}

// PCのみ表示する
.onlyPc {
  @include media-max(sp) {
    display: none !important;
  }
}

// アイコン
.textIcon {
  width: 1.1em;
  max-height: 1.1em;
  vertical-align: middle;
}

.textIconSmall {
  width: 0.7em;
  max-height: 0.7em;
  vertical-align: middle;
}

.textIconLarge {
  width: 1.5em;
  max-height: 1.5em;
  vertical-align: middle;
}

// リンク
.textLinkArrow {
  font-size: size(12);

  // PC
  @include media-min(pc) {
    font-size: size(16);
  }
}

// ***
// 文字装飾
// ***

.textUnderlined {
  text-decoration: underline;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textBold {
  font-weight: bold;
}

.textItalic {
  font-style: italic;
}

// 文字切れ三点リーダー
.textEllipsis {
  @include text-ellipsis;
}

// ***
// 文字/背景色
// ***
@each $name, $code in $colors {
  // 使用例
  // utilsStyles.reddishColor
  // utilsStyles.darkColor
  // utilsStyles.pinkishGrayColor
  .#{$name}Color {
    color: var(--color-#{$name}) !important;
  }
  .#{$name}BgColor {
    background-color: var(--color-#{$name}) !important;
  }
}

// ***
// 文字サイズ（汎用）
// ***

.textXSmall {
  font-size: size(10) !important;

  @include media-min(pc) {
    font-size: size(12) !important;
  }
}

.textSmall {
  font-size: size(12) !important;

  @include media-min(pc) {
    font-size: size(14) !important;
  }
}

.textMedium {
  font-size: size(14) !important;
  word-break: break-all;
  word-wrap: break-word;

  @include media-min(pc) {
    font-size: size(16) !important;
  }
}

.textLarge {
  font-size: size(15) !important;
  word-break: break-all;
  word-wrap: break-word;

  @include media-min(pc) {
    &:not(.spFont) {
      font-size: size(18) !important;
    }
  }
}

.textXLarge {
  font-size: size(18) !important;
  word-break: break-all;
  word-wrap: break-word;

  @include media-min(pc) {
    &:not(.spFont) {
      font-size: size(24) !important;
    }
  }
}

// ***
// マージン
// ***

.mtXSmall {
  margin-top: size(10);

  // PC
  @include media-min(pc) {
    margin-top: size(20);
  }
}

.mtSmall {
  margin-top: size(20);

  // PC
  @include media-min(pc) {
    margin-top: size(40);
  }
}

.mtSmallNoMediaDiff {
  margin-top: size(20);
}

.mtMedium {
  margin-top: size(40);

  // PC
  @include media-min(pc) {
    margin-top: size(80);
  }
}

.mtMediumNoMediaDiff {
  margin-top: size(40);
}

.mtLarge {
  margin-top: size(60);

  // PC
  @include media-min(pc) {
    margin-top: size(120);
  }
}

.mBSmall {
  margin-bottom: size(20) !important;

  @include media-min(pc) {
    margin-bottom: size(40) !important;
  }
}

.mLeftXSmall {
  margin-left: size(10) !important;

  @include media-min(pc) {
    margin-left: size(20) !important;
  }
}

.mtMediumNoMediaDiff {
  margin-top: size(40);
}

// ***
// フレックス
// ***

// 中央寄せ/横並び
.centered {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

//  中央寄せ/縦並び
.centeredVertical {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

// 右寄せ
.rightJustified {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// 右寄せ/縦並び
.rightJustifiedVertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  gap: size(10);
}

// 改行する
.textPrewrap {
  white-space: pre-wrap;
}

// リンク汎用
.textLink {
  color: var(--color-primary) !important;
  text-decoration: underline;
}

// 改行なし横並び
.flexRowNowrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.alignCenter {
  align-items: center !important;
}

.spaceBetween {
  justify-content: space-between !important;
}

.fullWidth {
  width: 100%;
}

.fullVwWidth {
  display: block;
  width: 100vw;
}

// HTML要素（HTMLで登録された部分をdangeourslySetで表示する際に使う）
.textHtml {
  word-break: break-all;

  a {
    color: var(--color-primary);
  }

  h1 {
    margin-top: 1em;
    margin-bottom: 1.4em;
    font-size: 1.6em;
    font-weight: bold;
  }

  h2 {
    padding-left: 0.5em;
    margin-top: 1em;
    margin-bottom: 1.2em;
    font-size: 1.4em;
    font-weight: bold;
    border-bottom: size(1) solid var(--color-primary);
    border-left: size(4) solid var(--color-primary);
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: 1.2em;
    font-weight: bold;
  }

  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.8em;
    font-size: 1em;
    font-weight: bold;
  }

  h5 {
    margin-top: 0.5em;
    margin-bottom: 0.6em;
    font-size: 1em;
  }

  h6 {
    margin-bottom: 0.6em;
    font-size: 1em;
  }

  p {
    margin-bottom: 0;

    &:empty {
      margin-bottom: 1em;
    }
  }

  ol {
    padding-left: 1.5em;
    list-style-type: decimal;
  }

  ul {
    padding-left: 1.5em;
    list-style-type: disc;
  }

  strong {
    font-size: 1em;
  }
}
