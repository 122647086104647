/* stylelint-disable color-no-hex */

// 色
$colors: (
  'primary': #f33,
  'gradationprimary': #e11111,
  'error': #f33,
  'famima': #00a33e,
  'gradationfamima': #00a33e,
  'black': #000,
  'cyanblack': #14171a,
  'dark': #252b37,
  'brownishgray': #666,
  'pinkishgray': #bfbfbf,
  'pinkishlightgray': #c0c0c0,
  'lightgray': #eee,
  'warmgray': #999,
  'gray': #dedede,
  'light': #f4f4f4,
  'white': #fff,
  "cobaltblue": #00B5DF,
  'darkskyblue': #36b3dc,
  'cyan': #00d0ec,
  'blue': #1da1f2,
  'green': #06c755,
  'purple': #3b579d,
);

// 幅
$width-pc: 1200; // PCの幅。
$width-pc-medium: 1000; // PCの幅。（内部コンテナなど）
$width-pc-small: 640; // PCの幅。（内部コンテナなど）
$width-sp: 800; // SPの幅。※ ブレークポイントにも使用する。
$width-left-pc: 46%;
$width-center-pc: 2%;
$width-right-pc: 52%;

// z-index
$z-index-header: 100;
$z-index-menu: 200;
$z-index-animation: 500;
$z-index-item-fab: 600;
$z-index-artist-fab: 600;
$z-index-modal: 700;
$z-index-swal: 1000;
