@use 'partials' as *;

.componentStyle {
  position: fixed;
  z-index: $z-index-header;
  width: 100vw;
  height: size(48);
  background-color: var(--color-white);
}

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.logo {
  display: block;
  width: size(116);
  height: size(20);
  margin-left: 3.2vw;
}

.auth {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: size(10);
  letter-spacing: size(1);
}

.signupButton {
  width: size(60);
  margin-right: 1.6vw;
  margin-left: 1.6vw;
}

.menuWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: size(50);
  height: 100%;
  font-size: size(10);
  cursor: pointer;
  background-color: var(--color-black);
}

.menuBadge {
  position: absolute;
  top: size(9);
  right: size(9);
}

.loginLink {
  font-weight: bold;
}

// SP
@include media-max(sp) {
  .menuOpened {
    position: absolute;
    z-index: initial;
  }
}

// PC
@include media-min(pc) {
  .componentStyle {
    height: size(100);
    border-bottom: size(1) solid var(--color-warmgray);
  }

  .menuOpened {
    border-radius: 0;
  }

  .logo {
    width: size(234);
    height: size(40);
    margin-left: size(40);
  }

  .auth {
    font-size: size(14);
  }

  .signupButton {
    width: size(120);
    margin-right: size(40);
    margin-left: size(40);
  }

  .menuWrapper {
    width: size(100);
    font-size: size(15);
    background-color: var(--color-black);
  }

  .menuBadge {
    top: size(30);
    right: size(30);
  }
}
