@use 'partials' as *;

.componentStyle {
  width: 100vw;
  font-size: size(10);
  color: var(--color-white);
  background-color: var(--color-black);
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: size(40) size(10);
}

.footerTitle {
  width: size(116);
}

.menuListWrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.menuList {
  width: 50%;
  margin-top: size(40);

  & > li {
    margin-bottom: size(20);
  }
}

.copyright {
  width: 100%;
  margin-top: size(40);
  text-align: center;
}

// PC
@include media-min(pc) {
  .componentStyle {
    min-width: size($width-pc);
    font-size: size(12);
  }

  .container {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto 50%;
    align-items: flex-start;
    width: size($width-pc);
    padding: size(80) 0;
    margin: 0 auto;
  }

  .footerTitle {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: size(141);
  }

  .menuListWrapper {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }

  .menuList {
    margin-top: 0;

    & > li {
      margin-bottom: size(37);
    }
  }

  .copyright {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    text-align: left;
  }
}
